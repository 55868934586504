import axios from "@/http/http";
import { Module, ActionTree } from "vuex";

const actions: ActionTree<any, any> = {
  getMCList({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(
          `/api/v1/skillofferings/nm/mandatory/courses/list/`,
          { params: data }
        );
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getMCListFilter({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(
          `/api/v1/skillofferings/nm/mandatory/courses/filter/`,
          { params: data }
        );
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getCertListFilter({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(
          `/api/v1/skillofferings/nm/certificate/filter/`,
          { params: data }
        );
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getCertGenerate({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(
          `/api/v1/skillofferings/nm/certificate/generate-admin/`,
          { params: data }
        );
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getMCAllEnrollments({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(
          `/api/v1/skillofferings/nm/mandatory/courses/enrollments/`,
          { params: data }
        );
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getCertCountData({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(
          `/api/v1/skillofferings/nm/certificate/data/`,
          { params: data }
        );
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getMCAllAssessments({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(
          `/api/v1/skillofferings/nm/mandatory/courses/assessments/`,
          { params: data }
        );
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getAllEnrolledListDownload({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(
          `/api/v1/skillofferings/nm/mandatory/courses/enrollments_download/`,
          { params: data }
        );
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  getAllAssessmentListDownload({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(
          `/api/v1/skillofferings/nm/mandatory/courses/assessments_download/`,
          { params: data }
        );
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getSubAdminCollegeStats({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/reports/subadmin/colleges/`, {
          params: data,
        });
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getSubAdminDistrictStats({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(
          `/api/v1/reports/subadmin/districts/`,
          { params: data }
        );
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getSubAdminDashboardStats({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/reports/subadmin/stats/`, {
          params: data,
        });
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getGenderCount({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(
          `/api/v1/reports/subadmin/gender_count/`
        );
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getAdminDashboardStats({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/reports/launch/stats/`);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  sendCollegeInvitations({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(
          `/api/v1/college/registration-invites/`,
          formData
        );

        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  reSendCollegeInvitations({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      // const formData = new FormData();
      //
      // Object.keys(data).forEach((key: string) => {
      //   formData.append(key, data[key]);
      // });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(
          `/api/v1/college/registration/resend_college_invites/`,
          data
        );

        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  getCollegesList({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("resetData");
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/college/list/`, {
          params: data,
        });
        commit("setCollegeListData", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getCollegeStats({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("resetData");
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/college/colleges-stats/`, {
          params: data,
        });
        // commit("setCollegeListData", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getCollegeDetails({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(
          `/api/v1/college/details/${data.collegeId}`
        );
        commit("setCollegeData", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  updateCollegeDetails({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data.data).forEach((key: string) => {
        formData.append(key, data.data[key]);
      });
      commit("SET_LOADING", true);
      try {
        if (data.collegeId == 0) {
          const response = await axios.post(
            `/api/v1/college/details/${data.collegeId}/`,
            formData
          );
          resolve(response.data);
        } else {
          const response = await axios.patch(
            `/api/v1/college/details/${data.collegeId}/`,
            formData
          );
          // commit("setCollegeData", response.data);
          resolve(response.data);
        }
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  updateMCDetails({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data.data).forEach((key: string) => {
        formData.append(key, data.data[key]);
      });
      commit("SET_LOADING", true);
      try {
        if (data.mc_ID == 0) {
          const response = await axios.post(
            `/api/v1/skillofferings/nm/mandatory/course/${data.mc_ID}/`,
            formData
          );
          resolve(response.data);
        } else {
          console.log(data.mc_ID);
          const response = await axios.patch(
            `/api/v1/skillofferings/nm/mandatory/course/${data.mc_ID}/`,
            formData
          );
          // commit("setCollegeData", response.data);
          resolve(response.data);
        }
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  getAdminCoursesList({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("resetData");
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/lms/courses/`, {
          params: data,
        });
        commit("setAdminCoursesListData", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  adminApproveRejectCourse({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        if (key != "course_id") {
          formData.append(key, data[key]);
        }
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(
          `/api/v1/lms/course/update/${data.course_id}/`,
          formData
        );
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getPlacementOverview({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(
          `api/v1/placements/vm_placement_details/`,
          { params: data }
        );
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getAllUsers({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`api/v1/users/list/`, {
          params: data,
        });
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getAllRoles({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`api/v1/data/account-role/`, {
          params: data,
        });
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  updateUserDetails({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data.data).forEach((key: string) => {
        formData.append(key, data.data[key]);
      });
      commit("SET_LOADING", true);
      try {
        if (data.user_id == 0) {
          const response = await axios.post(
            `/api/v1/user/${data.user_id}/`,
            formData
          );
          resolve(response.data);
        } else {
          const response = await axios.patch(
            `/api/v1/user/${data.user_id}/`,
            formData
          );
          // commit("setCollegeData", response.data);
          resolve(response.data);
        }
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  sendInviteUser({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(
          `/api/v1/user/send_invite/${data.user_id}/`,
          formData
        );

        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  submitMailSchedule({ commit }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key) => {
        // IF THE data[key] IS AN ARRAY, APPEND EACH FILE INDIVIDUALLY
        if (Array.isArray(data[key])) {
          data[key].forEach((file: any) => {
            formData.append(key, file);
          });
        } else {
          formData.append(key, data[key]);
        }
      });

      commit("SET_LOADING", true);
      try {
        const response = await axios.post(
          `/api/v1/student/send-mail/`,
          formData
        );
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getFdpAcademicYearsList({ commit }) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/student/fetch_academic_year`);
        commit("saveFdpAcademicYearsList", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }

      commit("SET_LOADING", false);
    });
  },
  getFdpCollegeTypesByAcademicYear({ commit }, data) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(
          `/api/v1/student/fetch_college_type_by_academic_year/`,
          { params: data }
        );
        commit("saveFdpCollegeTypesForAcademicYear", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }

      commit("SET_LOADING", false);
    });
  },
  getFdpAffUniversityListByCollegeType({commit}, data) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/student/fetch_university_by_college_type/`, { params: data });
        commit('saveFdpAffUniversityListByCollegeType', response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }

      commit("SET_LOADING", false);
    });
  },
  getFdpSemestersListByCollegeType({commit}, data) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(
          `/api/v1/student/fetch_semesters_by_college_type/`,
          { params: data }
        );
        commit("saveFdpSemestersListByCollegeType", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }

      commit("SET_LOADING", false);
    });
  },
  getFdpCourseListByAcademicYear({ commit }, data) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(
          `/api/v1/student/fetch_courses_by_filters/`,
          { params: data }
        );
        commit("saveFdpCourseListByAcademicYear", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }

      commit("SET_LOADING", false);
    });
  },
  getFdpPartnerListByCourse({commit}, data) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/student/fetch_knowledge_partners_by_course/`, { params: data });
        commit('saveFdpPartnerListByCourse', response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }

      commit("SET_LOADING", false);
    });
  },
  getFdpDistrictByCollegeType({ commit }, data) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(
          `/api/v1/student/fetch_district_by_college_type/`,
          { params: data }
        );
        commit("saveFdpDistrictByCollegeType", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }

      commit("SET_LOADING", false);
    });
  },
  getFdpDistrictNameByCollegeType({ commit }, data) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(
          `/api/v1/student/fetch_district_name_by_college_type/`,
          { params: data }
        );
        commit("saveFdpDistrictNameByCollegeType", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }

      commit("SET_LOADING", false);
    });
  },
  getTpDataList({ commit }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(
          `/api/v1/skillofferings/fetch_tp_dashboard_details`,
          { params: data }
        );
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }

      commit("SET_LOADING", false);
    });
  },
};
const adminStore: Module<any, any> = {
  state: () => ({
    college: {},
    colleges: [],
    coursesList: [],
    studentCount: [],
    totalPages: 0,
    collegesCount: 0,
    limit: 20,
    page: 0,
  }),
  mutations: {
    resetData(state) {
      state.colleges = [];
      state.studentCount = [];
      state.collegesCount = 0;
      state.verifiedCollegesCount = 0;
      state.coursesPage = 0;
      state.totalPages = 0;
      state.limit = 20;
      state.page = 0;
    },
    setCollegeData(state, data) {
      state.college = data;
    },
    setCollegeListData(state, data) {
      state.colleges = data.colleges_list;
      state.studentCount = data.student_count;
      state.collegesCount = data.total_count;
      state.verifiedCollegesCount = data.colleges_verified_count;
      state.totalPages = Math.ceil(data.total_count / data.limit);
      state.limit = data.limit;
      state.page = data.page;
    },
    setAdminCoursesListData(state, data) {
      state.coursesList = data.courses_list;
      // state.coursesCount = data.total_count
      state.totalPages = Math.ceil(data.total_count / data.limit);
      state.limit = data.limit;
      state.coursesPage = data.page;
    },

    saveFdpAcademicYearsList(state, data: any) {
      state.fdpAcademicYearsList = data.data;
    },
    saveFdpCollegeTypesForAcademicYear(state, data: any) {
      state.fdpCollegeTypesByAcademicYear = data.data;
    },
    saveFdpAffUniversityListByCollegeType(state, data: any) {
      state.fdpAffUniversityListByCollegeType = data.data;
    },
    saveFdpSemestersListByCollegeType(state, data: any) {
      state.fdpSemestersListByCollegeType = data.data;
    },
    saveFdpCourseListByAcademicYear(state, data: any) {
      state.fdpCourseListByAcademicYear = data.data;
    },
    saveFdpPartnerListByCourse(state, data: any) {
      state.fdpPartnerListByCourse = data.data;
    },
    saveFdpDistrictByCollegeType(state, data: any) {
      state.fdpDistrictByCollegeType = data.data;
    },
    saveFdpDistrictNameByCollegeType(state, data: any) {
      state.fdpDistrictNameByCollegeType = data.data;
    },
  },
  actions,
  getters: {
    adminCollegeList(state) {
      return state.colleges;
    },
    adminCollegeCount(state) {
      return state.collegesCount;
    },
    districtStudentCount(state) {
      return state.studentCount;
    },
    adminVerifiedCollegesCount(state) {
      return state.verifiedCollegesCount;
    },
    universityStudentCount(state) {
      return state.studentCount;
    },
    adminCoursesPage(state) {
      return state.coursesPage;
    },
    adminCoursesList(state) {
      return state.coursesList;
    },
    collegesTotalPages(state) {
      return state.totalPages;
    },
    collegesPage(state) {
      return state.page;
    },
    adminCollege(state) {
      return state.college;
    },

    fdpAcademicYearsList(state) {
      return state.fdpAcademicYearsList || [];
    },
    fdpCollegeTypesByAcademicYear(state) {
      return state.fdpCollegeTypesByAcademicYear || [];
    },
    fdpAffUniversityListByCollegeType(state) {
      return state.fdpAffUniversityListByCollegeType || [];
    },
    fdpSemestersListByCollegeType(state) {
      return state.fdpSemestersListByCollegeType || [];
    },
    fdpCourseListByAcademicYear(state) {
      return state.fdpCourseListByAcademicYear || [];
    },
    fdpPartnerListByCourse(state) {
      return state.fdpPartnerListByCourse || [];
    },
    fdpDistrictByCollegeType(state) {
      return state.fdpDistrictByCollegeType || [];
    },
    fdpDistrictNameByCollegeType(state) {
      return state.fdpDistrictNameByCollegeType || [];
    },
  },
};

export default adminStore;
